/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can 
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

// Use this variable to set up the common and page specific functions. If you 
// rename this variable, you will also need to rename the namespace below.
var Bace = {
	
	// All pages
	common: {
		init: function() {
		
		
			$.fn.closeCanvas = function() {
				var $width = this.width();
				if ( $width > 600 ) {
					$('body').removeClass('canvas-slid');
					$('body').removeAttr('style');
					$('#nav-mobile').removeClass('in');
					$('#nav-mobile').removeClass('canvas-slid');
					$('#nav-mobile').removeAttr('style');				
				}
			};
			
			$(window).resize(function() {
				$('.site_info .container').closeCanvas();
			});
			

			// GALLERY CAPTIONS
			$(".jCaption").each(function() {
				
				var imageCaption = $(this).attr("alt");
								
				if ( $(this).hasClass("img--right") ) {
					$(this).removeClass("img--right");
					$(this).wrap( '<div class="img-captioned img--right"></div>' );	
					$("<span>"+imageCaption+"</span>").addClass('caption').insertAfter(this);
					return;
				}

				if ( $(this).hasClass("img--left") ) {
					$(this).removeClass("img--left");
					$(this).wrap( '<div class="img-captioned img--left"></div>' );	
					$("<span>"+imageCaption+"</span>").addClass('caption').insertAfter(this);
					return;
				}

				$(this).wrap( '<div class="img-captioned"></div>' );	
				$("<span>"+imageCaption+"</span>").addClass('caption').insertAfter(this);
				return;
				
			});		
			
		}
	},
	
	page_front: {
		init: function() {
			
			$('.slider_jumbo').slick({
				autoplay: true,
				autoplaySpeed: 6000,
				arrows: false,
				fade: true,
				infinite: true,
				mobileFirst: true,
				pauseOnFocus: false,
				pauseOnHover: false,
				slidesToShow: 1,
				slidesToScoll: 1,
				slide: 'div',
				/*responsive: [
					{
						breakpoint: 992,
						settings: {
							dots: true,
						}
					}
				]*/
			});	
			
		}
	},	
	
	page_faq: {
		init: function() {
		
			var faqPanels = $('.faq_item .faq_body').hide();
			var faqTitles = $('.faq_item');
		
			$('.faq_first .faq_body').show();
			$('.faq_first').addClass('open');
		
			$('.faq_item > .faq_title > h2').click(function() {
				
				
				if ( $(this).parent().parent().hasClass('open') ) {
					
					$(this).parent().parent().removeClass('open');
					$(this).parent().next().slideUp();
					
				} else {
				
					faqPanels.slideUp();
					
					$('.faq_item').each(function() {
						$(this).removeClass('open');
					});
					
					$(this).parent().parent().addClass('open');
					$(this).parent().next().slideDown();
					return false;
					
				}
				
			});
		
		}		
	}
	
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
	fire: function(func, funcname, args) {
		var namespace = Bace;
		funcname = (funcname === undefined) ? 'init' : funcname;
		if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
			namespace[func][funcname](args);
		}
	},
	loadEvents: function() {
		UTIL.fire('common');
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/),function(i,classnm) {
				UTIL.fire(classnm);
			});
	}
};

$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.